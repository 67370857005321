import React, {useState} from 'react';
import {closePopup, isWindowPrefsOpen} from "../../store/popupMgr";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import e3Style from '../../../css/e3-style.css';
import ModalWrapper from "./modalWrapper";
import {
	getDisplayPrefSettings,
	isDeafBlind,
	saveDisplaySettings,
	setDeafBlind,
	setScaleSelection
} from "../../store/display";
import {getCsrfSessionNonce} from "../../store/user";
import useAutoFocus from "../util/useAutoFocus";

const font_scale_200 = "font-scale-200";
const font_scale_150 = "font-scale-150";
export const font_scale_100 = "font-scale-100";

const WindowPrefs = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isDfBl = useSelector(isDeafBlind);
	const dispPrefs = useSelector(getDisplayPrefSettings);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [visState, setVisState] = useState(isDfBl);
	const [scaleState, setScaleState] = useState(dispPrefs.scaleSelection);
	const aFocus = useAutoFocus();

	const setScale = e => {
		setScaleState(e.target.value);
	};

	const saveClick = async e => {
		e.preventDefault();
		if (visState !== isDfBl) setDeafBlind(dispatch, visState);
		if (scaleState !== dispPrefs.scaleSelection) setScaleSelection(dispatch, scaleState);
		await saveDisplaySettings(dispatch, {...dispPrefs, scaleSelection: scaleState, deafBlind: visState}, csrfSessionNonce, () => {});
		closePopup(dispatch);
	};

	const cancelClick = e => {
		e.preventDefault();
		closePopup(dispatch);
	};

	return (
		<>
			<h4>{t('page.popup.winpref')}</h4>

			<div className={e3Style['sprint--modal-submit']}>
				<button type="button" className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
				        onClick={cancelClick}>
					{t('button.ok')}
				</button>
			</div>
		</>
	);
};

const ModalWindow = ModalWrapper(WindowPrefs);

const WindowPrefsModal = () => {
	const isOpen = useSelector(isWindowPrefsOpen);
	return <ModalWindow isOpen={isOpen}/>;
};

export default WindowPrefsModal;
