import React, {useEffect, useRef, useState} from 'react';
import e3Style from '../../../../css/e3-style.css';
import sStyle from '../../../../css/sprint-style.css';
import sprStyle from '../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
	getCallInProgSel,
	getCallState,
	isExtCallInProg,
	resetBackfillState, resetCalldoneState,
	setCallActionState, setCalldoneState,
	setDialingActionState,
	setDialingBackfillState,
	setInCallState,
	USER_ACTION_DIALING,
	USER_ACTION_DONE,
	USER_ACTION_END_CALL,
	USER_ACTION_READY
} from "../../../store/call";
import {getCsrfSessionNonce, getUserInfo, isLoggedIn} from "../../../store/user";
import {getDisplayPrefSettings, getFontScaleClass, getShowHeaders, saveDisplaySettings, setAutoSend, 
	    isLowVision, isDeafBlind, setDeafBlind, setConvoSize, setScaleSelection, setShowHeaders} from "../../../store/display";
import {setAutoFocusFunc, setHistoryPath} from "../../../store/bodyView";
import {focusIfNoPopup, isAnyWindowOpen, openWindowPrefs} from "../../../store/popupMgr";
import ConvoArea from "../../call/convoArea";
import ChatTextArea from "../../call/chatTextArea";
import {
	addConvoData,
	clearConvoData,
	generateEmailBody,
	getConvoDataSel,
	isTransmitEnabledSel,
	submitUserText
} from "../../../store/convoLog";
import {dispPhoneNum, fmtPhoneNum, stripNonNums} from "../../util/stringFormatHelper";
import CallTimer from "../Call/callTimer";
import CallErrorStatus from "../Call/callErrorStatus";
import {getFreqNumsNameSorted, loadFreqDialedNums} from "../../../store/FreqNumberList";
import {loginPath} from "../../layout/body";
import {useNavigate} from "react-router";

const convoSizeCSS = ['call-chat-container-1','call-chat-container-2', 'call-chat-container-3', 'call-chat-container-4','call-chat-container-5','call-chat-container-6','call-chat-container-7','call-chat-container-8','call-chat-container-9','call-chat-container-10']

const convoFontSizeCSS = ['call-chat-font-1','call-chat-font-2', 'call-chat-font-3', 'call-chat-font-4', 'call-chat-font-5']

const initSate = {fdnValue: "", validDialToNumber: false, dialToNumber: "", dialingInstr: "", refocus: false};
const resetCallState = setState => setState({...initSate, refocus: true});

const btnAction = (dispatch, callData, callState, setCallState) => {
	switch (callData.actionState) {
		case USER_ACTION_READY:
			setDialingActionState(dispatch, {callPhoneNumber: callState.dialToNumber, dialingInstr: callState.dialingInstr});
			break;
		case USER_ACTION_DIALING:
			resetCallState(setCallState);
			clearConvoData(dispatch);
			setCallActionState(dispatch, USER_ACTION_READY);
			break;
		case USER_ACTION_END_CALL:
			setInCallState(dispatch, false);
			break;
		case USER_ACTION_DONE:
			resetCallState(setCallState);
			clearConvoData(dispatch);
			setCallActionState(dispatch, USER_ACTION_READY);
			break;
	}
};

const disableABFunc = (callData, userData, callState) => callData.actionState === USER_ACTION_READY &&
	(isExtCallInProg(callData) || (isLoggedIn(userData) && !callState.validDialToNumber));

const UserActionButton = ({translate, dispatch, callData, callState, setCallState, userData}) => {
	let userActionText;
	const actionClick = e => {
		e.preventDefault();
		btnAction(dispatch, callData, callState, setCallState);
	};
	switch (callData.actionState) {
		case USER_ACTION_READY:
			userActionText = isLoggedIn(userData) ? 'button.dial' : 'button.signin';
			break;
		case USER_ACTION_DIALING:
			userActionText = 'button.cancel';
			break;
		case USER_ACTION_END_CALL:
			userActionText = 'button.end';
			break;
		case USER_ACTION_DONE:
			userActionText = isLoggedIn(userData) ? 'button.done' : 'button.signin';
			break;
		default:
			userActionText = '';
			break;
	}

	return <button type="button" id="user_action_button"
				   className={`${e3Style.button} ${e3Style['button--minwidth-md']} ${e3Style['button--md']} ${e3Style['display-block']} ${e3Style['mt-20']} ${e3Style['mb-20']}`}
				   disabled={disableABFunc(callData, userData, callState)} onClick={actionClick}>{translate(userActionText)}</button>;
};

const selectText = ref => {
	if (document.selection) {
		const range = document.body.createTextRange();
		range.moveToElementText(ref.current);
		range.select();
	} else if (window.getSelection) {
		const range = document.createRange();
		range.selectNode(ref.current);
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range);
	}
};

const clearSelection = () => {
	if (document.selection) {
		const range = document.body.createTextRange();
		range.select();
	} else if (window.getSelection) {
		window.getSelection().removeAllRanges();
	}
};

const copyConvo = ref => e => {
	e.preventDefault();
	try {
		selectText(ref);
		document.execCommand('copy');
		clearSelection();
	} catch (err) {
		console.log(err);
	}
};

const emailConvo = (convoData, agtTxt, lowVisFlag) => e => {
	e.preventDefault();
	window.open("mailto:?subject=IP%20Relay%20Conversation&body=" + generateEmailBody(convoData, agtTxt, lowVisFlag), "_blank");
};

export const dialPhNum = (num, instr, dispatch, callData) => {
	if (callData.actionState === USER_ACTION_READY) {
		const rawNum = num?.length ? stripNonNums(num) : "";
		const dialToNumber = rawNum.length === 11 && rawNum.charAt(0) === "1" ? rawNum.substring(1) : rawNum;
		if (dialToNumber.length === 10 || /^[2-9]11$/.test(dialToNumber))
			setDialingBackfillState(dispatch, {callPhoneNumber: dialToNumber, dialingInstr: instr});
	}
};

export const finishCall = (dispatch, callData) => {
	if (callData.actionState === USER_ACTION_DONE) {
		clearConvoData(dispatch);
		setCalldoneState(dispatch);
	}
};

const Call = () => {
	const { t } = useTranslation();
	const freqNbrList = useSelector(getFreqNumsNameSorted);
	const [callState, setCallState] = useState(initSate);
	const [userText, setUserText] = useState('');
	const callData = useSelector(getCallState);
	const userData = useSelector(getUserInfo);
	const scaleClass = useSelector(getFontScaleClass);
	const inCall = useSelector(getCallInProgSel);
	const isTransmitEnabled = useSelector(isTransmitEnabledSel);
	const isPopupOpen = useSelector(isAnyWindowOpen);
	const convoData = useSelector(getConvoDataSel);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const dispPrefs = useSelector(getDisplayPrefSettings);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const afRef = useRef();
	const caRef = useRef();
	const isReadOnly = !inCall || !isTransmitEnabled;
	const isLowVis = useSelector(isLowVision);
	const isDfBl = useSelector(isDeafBlind);

	const [divHeight, setDivHeight] = useState(Number(dispPrefs.convoSize));
	const [fontScale, setFontScale] = useState(Number(dispPrefs.scaleSelection));
	
	const toggleDfBlState = e => {
		e.preventDefault();
		const {checked} = e.target;
		saveDisplaySettings(dispatch, {...dispPrefs, deafBlind: checked}, csrfSessionNonce, (dispatch, response) => {
			if (response !== null) {
				if (response.status === 200) {
					if (response.data.status.code === 200) {
						setDeafBlind(dispatch, checked);
					} else if (response.data.status.code === 998) {
						setHistoryPath(dispatch, navigate, loginPath);
					}
				}
			}
		});		
	}
	
	const changeConvoHeight = () => {
		if(divHeight === 10)
			setDivHeight(1);
		else
			setDivHeight(divHeight+1);
	};
	
	const changeFontScale = () => {
		if(fontScale === 5)
			setFontScale(1);
		else
			setFontScale(fontScale+1);
	};
	
	const toggleHeaders = () => {
		if ( dispPrefs.showHeaders === undefined )
			setShowHeaders(dispatch, false);
		else 
			setShowHeaders(dispatch, !dispPrefs.showHeaders);
	}
	const saveConvoSize = () => {
		saveDisplaySettings(dispatch, {...dispPrefs, convoSize: divHeight.toString(), scaleSelection: fontScale.toString() }, csrfSessionNonce, (dispatch, response) => {
			if (response !== null) {
				if (response.status === 200) {
					if (response.data.status.code === 200) {
						setConvoSize(dispatch, divHeight.toString());
						setScaleSelection(dispatch, fontScale.toString());
					} else if (response.data.status.code === 998) {
						setHistoryPath(dispatch, navigate, loginPath);
					}
				}
			}
		});		
	}
	
	const sendUserText = text => {
		if (text.length) submitUserText(dispatch, text);
	};

	const sendAllUserText = e => {
		e.preventDefault();
		if (userText.length) {
			if(userText.length <= 800 ) {
				sendUserText(userText);
				setUserText('');
			}
			else{
				openWindowPrefs(dispatch);
			}
		}
	};

	const setDialNum = num => {
		const rawNum = num?.length ? stripNonNums(num) : "";
		const dialToNumber = rawNum.length === 11 && rawNum.charAt(0) === "1" ? rawNum.substring(1) : rawNum;
		const validDialToNumber = dialToNumber.length === 10 || /^[2-9]11$/.test(dialToNumber)||dialToNumber === '988';
		setCallState({...callState, fdnValue: dialToNumber, dialToNumber, validDialToNumber});
	};

	const handleDialNumChg = ({target: ct}) => {
		setDialNum(ct.value);
	};

	const setDialingInstr = ({target: ct}) => {
		setCallState({...callState, dialingInstr: ct.value});
	};

	const readonlyActionInput = !isLoggedIn(userData) || callData.actionState !== USER_ACTION_READY;

	const dialKeyDown = e => {
		if (e.keyCode === 13) {
			e.preventDefault();
			if (!readonlyActionInput && callState.validDialToNumber) {
				btnAction(dispatch, callData, callState, setCallState);
			}
		}
	};

	const copyFreqNum = e => {
		e.preventDefault();
		const {value} = e.target;
		setDialNum(value);
	};

	useEffect(() => {
		if (callData.backfill){
			const dialToNumber = callData.callPhoneNumber;
			setCallState({...callState, fdnValue: dialToNumber, dialToNumber, validDialToNumber: true, dialingInstr: callData.dialingInstr});
			resetBackfillState(dispatch);
		} else if (callData.calldone){
			resetCallState(setCallState);
			resetCalldoneState(dispatch);
		}
	}, [callData]);
	useEffect(() => {
		if (callState.refocus) {
			setTimeout(() => afRef.current?.focus(), 10);
			setCallState({...callState, refocus: false});
		}
	}, [callState.refocus]);
	useEffect(() => {
		loadFreqDialedNums(dispatch, csrfSessionNonce, () => {}, () => {});
		setAutoFocusFunc(dispatch, () => afRef.current?.focus());
		focusIfNoPopup(isPopupOpen, afRef);
	}, []);

//	const showWindowPrefs = e => {
//		e.preventDefault();
//		openWindowPrefs(dispatch);
//	};

	const toggleAutoSend = e => {
		e.preventDefault();
		const {checked} = e.target;
		saveDisplaySettings(dispatch, {...dispPrefs, autoSend: checked}, csrfSessionNonce, (dispatch, response) => {
			if (response !== null) {
				if (response.status === 200) {
					if (response.data.status.code === 200) {
						setAutoSend(dispatch, checked);
					} else if (response.data.status.code === 998) {
						setHistoryPath(dispatch, navigate, loginPath);
					}
				}
			}
		});
	};

	return (
		<main role="main" aria-label="main content" className={(dispPrefs.showHeaders === undefined) ? e3Style['headers-visible']:( dispPrefs.showHeaders ? e3Style['headers-visible']:e3Style['headers-hidden'])}>
			<div className={e3Style['container-xl']}>
				<div className={`${e3Style['row']} ${e3Style['sprint-ctas']} ${e3Style['pt-20']}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-6']} ${e3Style['col-lg-6']}`}>
						<div className={e3Style['sprint-cta_chat_info']}>
							<div className={e3Style['call-info']}>
								<CallErrorStatus/>
								<CallTimer/>
								{isLoggedIn(userData) && callData.userPhoneNumber &&
									<>
										<div className={e3Style['user-phone-number']}>
											<h2>{t('page.call.1')}</h2>
											<h3>{fmtPhoneNum(callData.userPhoneNumber)}</h3>
										</div>
										<label id="freq-dialed-nums-list-label" htmlFor="freq-dialed-nums-list"><span>{t('page.call.freqdialed')}</span>
											<a href="#" className={`${sprStyle.tooltip} ${e3Style['sprint--information-icon']} ${e3Style['sprint-icon-info']}`}
											   aria-describedby="freq-dialed-tooltip" ng-click="preventAction($event)">
												<span role="tooltip" id="freq-dialed-tooltip" className={sprStyle.tooltiptext}>{t('page.call.freqdialed_tooltip')}</span></a>
										</label>
										<select id="freq-dialed-nums-list" onChange={copyFreqNum} value={callState.fdnValue} disabled={readonlyActionInput}>
											<option value="">{t('page.call.freqchoose')}</option>
											{
												freqNbrList?.length > 0 && freqNbrList.map(freqNbr =>
													<option key={freqNbr.ORDERID} value={freqNbr.PHONE_NUMBER}>{freqNbr.NAME}</option>
											)}
										</select>
									</>
								}
								<label id="phone_number_to_call_label" htmlFor="phone_number_to_call">{t('page.call.2')}</label>
								<input id="phone_number_to_call" type="text" readOnly={readonlyActionInput} ref={afRef} onChange={handleDialNumChg} onKeyDown={dialKeyDown} value={dispPhoneNum(callState.dialToNumber)} autoComplete="off"/>
								{isLoggedIn(userData) && callData.userPhoneNumber &&
									<>
										<label id="dialing_instructions_label" htmlFor="dialing_instructions">{t('page.call.4')}</label>
										<textarea name="dialing_instructions" ng-model="dialWindow.dialingInstructions"
										          readOnly={readonlyActionInput} id="dialing-instructions"
										          cols="4" rows="1" onChange={setDialingInstr} value={callState.dialingInstr}/>
									</>
								}
								<UserActionButton translate={t} dispatch={dispatch} userData={userData}
												  callData={callData} callState={callState} setCallState={setCallState}/>
								{callData.actionState === USER_ACTION_DONE &&
									<>
										<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-md']}
											${e3Style['button--md']} ${e3Style['display-block']} ${e3Style['mt-20']}
											${e3Style['mb-20']}`} onClick={copyConvo(caRef)}>{t('button.copy')}</button>
										<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-md']}
											${e3Style['button--md']} ${e3Style['display-block']} ${e3Style['mt-20']}
											${e3Style['mb-20']}`} onClick={emailConvo(convoData, dispPhoneNum(callState.dialToNumber), isLowVis)}>{t('button.email')}</button>
									</>
								}
							</div>
						</div>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-18']} ${e3Style['col-lg-18']}`}>
						<div className={e3Style['sprint-cta_chat_box']}>
							<div className={`${e3Style['cta-chat']} ${!!scaleClass && e3Style[scaleClass]}`}>
								<div className={e3Style['row']}>
									<div className={e3Style['colStyle']}>
										<label id="convo-area-label" className={sStyle['std-txt-bold']}>
											{t('page.call.9')}&nbsp;
										</label>
										&nbsp;&nbsp;
										<a id="changeconvosize" href='#' className={`${e3Style['button--link']} ${e3Style['sprint-remove_item']}`}  
										onClick={e => {e.preventDefault();changeConvoHeight()}}>{t('page.call.chgconvosize')}</a>
										&nbsp;&nbsp;&nbsp;&nbsp;
										<a id="changefontsize" href='#' className={`${e3Style['button--link']} ${e3Style['sprint-remove_item']}`}   
										onClick={e => {e.preventDefault();changeFontScale()}}>{t('page.call.chgfontsize')}</a>
										&nbsp;&nbsp;&nbsp;&nbsp;
										<a id="toggleheaders" href='#' className={`${e3Style['button--link']} ${e3Style['sprint-remove_item']}`}   
										onClick={e => {e.preventDefault();toggleHeaders()}}>
										{ (dispPrefs.showHeaders === undefined) ? t('page.call.hideheaders'):(dispPrefs.showHeaders ? t('page.call.hideheaders'):t('page.call.showheaders'))}</a>
										&nbsp;&nbsp;
									</div>
									<div className={`${e3Style.colStyle} ${e3Style['ml-auto']}`}>
										<a id="save-sizes" href='#' className={`${e3Style['button--link']} ${e3Style['sprint-remove_item']}`} 
										onClick={e => {e.preventDefault();saveConvoSize()}}>{t('page.call.savesizes')}</a>
									</div>
								</div>
								<div className={ `${e3Style[convoSizeCSS[divHeight-1]]} ${e3Style[convoFontSizeCSS[fontScale-1]]}`}>
									<ConvoArea ref={caRef}/>
								</div>		
								<div className={`${e3Style['call-chat-input']} ${e3Style[convoFontSizeCSS[fontScale-1]]}`}>
									<label id="chat_text_area_label" htmlFor="chat_text_area" className={sStyle['std-txt-bold']}>
										{t('page.call.6')}
									</label>
									<ChatTextArea userText={userText} setUserText={setUserText} sendUserText={sendUserText} isReadOnly={isReadOnly}/>
								</div>
								<div className={e3Style['sprint-cta_chat-buttons']}>
									<label className={e3Style.switch}> 
									<input type="checkbox" checked={isDfBl} onClick={toggleDfBlState}/>
										<span>{t('page.popup.screenrdr')}</span>
									</label>
									<label className={e3Style.switch}>
										<input type="checkbox" checked={dispPrefs.autoSend} onClick={toggleAutoSend}/>
										<span>{t('page.call.8')}</span>
										<a href="#" className={`${sprStyle.tooltip} ${e3Style['sprint--information-icon']} ${e3Style['sprint-icon-info']}`}
										   aria-describedby="security-answer-tooltip">
											<span role="tooltip" id="security-answer-tooltip" className={sprStyle.tooltiptext}>{t('page.call.10')}</span></a>
									</label>
									<button aria-labelledby="send text" type="button" className={`${e3Style['button']} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
									        disabled={isReadOnly || !userText.length} onClick={sendAllUserText}>
										{t('button.send')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default Call;